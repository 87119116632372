@charset "utf-8";

@import '_reset.scss';
@import '_adobeblank.scss';
@import '_yugothic.scss';
@import '_variables.scss';



html{
	width: 100%;
	height: 100%;
}



body{
	width: 100%;
	height: 100%;
	color: $hiperdyne_white;
	font-family: $font_gotham;
	font-style: normal;
	font-weight: $font_weight_book;
	font-size: 14px;
	line-height: 28px;
	letter-spacing: .05em;
	text-align: left;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	font-feature-settings: 'palt';
	background-color: $hiperdyne_black;
	overflow: auto;
}



a{
	color: $hiperdyne_white;
	text-decoration: underline;
	text-decoration-color: $hiperdyne_white;
	outline: none;
	transition: color .3s ease-out, background-color .3s ease-out;

	&.hover{
		color: $hiperdyne_black;
		background-color: $hiperdyne_white;
		transition: color 0s ease-out, background-color 0s ease-out;
	}
}



/****************************** COMMON ******************************/



.clear{
	display: block;
	clear: both;
}



.nowrap{
	display: inline-block;
	white-space: nowrap;
}



.hidden{
	display: none;
}



::selection{
	color: $hiperdyne_black;
	background-color: $hiperdyne_white;
}



.weight_book{
	font-family: $font_gotham;
	font-weight: $font_weight_book;
	transform: rotate(0.0001deg);
}



.weight_bold{
	font-family: $font_gotham;
	font-weight: $font_weight_bold;
	transform: rotate(0.0001deg);
}



/****************************** CONTENTS ******************************/



#wrapper{
	position: relative;
	width: 100%;
	height: 100%;

	#bg{
		&.readable{
			opacity: .5;
		}

		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		overflow: hidden;
		transition: opacity 1s ease-in-out;

		#bg_container{
			&.show{
				@media #{$mq_s-ml_s}{
					transition: height .5s cubic-bezier(0.5, 0, 0, 1), opacity 3s ease-in-out;
				}
				@media #{$mq_s-ml_ml}{
					transition: opacity 3s ease-in-out;
				}
				opacity: 1;
			}

			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			// height
			// background-image
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			opacity: 0;
			transition: height 1s cubic-bezier(0.5, 0, 0, 1);
		}
	}

	#language{
		@media #{$mq_sm-l_sm}{
			position: fixed;
			top: 8px;
			left: 20px;
		}
		@media #{$mq_sm-l_l}{
			position: fixed;
			top: 8px;
			left: 50%;
			width: 50%;
		}
		font-size: 14px;
		z-index: $z-index_language;

		.underscore{
			position: absolute;
			top: 24px;
			left: 0px; //
			width: 1px; //
			height: 1px;
			background-color: $hiperdyne_white;
			transition: left .5s cubic-bezier(0.5, 0, 0, 1), width .5s cubic-bezier(0.5, 0, 0, 1);
		}

		.item{
			opacity: .5;
		}

		.lang{
			text-decoration: none;
			background-color: transparent;

			&.hover{
				color: rgba($hiperdyne_white, .5);
			}
		}
	}

	#header{
		@media #{$mq_sm-l_sm}{
			width: 100%;
		}
		@media #{$mq_sm-l_l}{
			width: 50%;
		}
		position: fixed;
		top: 0px;
		left: 0px;
		height: 100%;

		#logo{
			@media #{$mq_sm-l_sm}{
				width: calc(100% - 40px);
				max-width: 240px;
				transition: top .5s cubic-bezier(0.5, 0, 0, 1);
			}
			@media #{$mq_sm-l_l}{
				width: 240px;
			}
			position: absolute;
			// top
			left: 50%;
			height: 0px;
			padding-bottom: 104.16666%;
			background-image: url('/asset/img/logo.svg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	#contents{
		@media #{$mq_sm-l_sm}{
			position: relative;
			width: calc(100% - 40px);
			margin: 0px auto;
		}
		@media #{$mq_sm-l_l}{
			position: relative;
			width: 50%;
			margin-left: 50%;
		}
		position: relative;
		// margin-top
		padding-bottom: 15px;
		// opacity

		.page_identifier{

			&.en{
				#catch{
					h1{
						@media #{$mq_s-m-l_s}{
							font-size: 32px;
							line-height: 48px;
						}
						@media #{$mq_s-m-l_m}{
							font-size: 38px;
							line-height: 58px;
						}
						@media #{$mq_s-m-l_l}{
							font-size: 60px;
							line-height: 72px;
						}
					}
				}
				.section{
					h2{
						font-size: 24px;
						line-height: 24px;
					}
				}
			}

			&.ja{
				#catch{
					h1{
						@media #{$mq_s-m-l_s}{
							font-size: 40px;
							line-height: 48px;
						}
						@media #{$mq_s-m-l_m}{
							font-size: 48px;
							line-height: 58px;
						}
						@media #{$mq_s-m-l_l}{
							font-size: 48px;
							line-height: 72px;
						}
					}
				}
				.section{
					h2{
						font-size: 21px;
						line-height: 24px;
					}
				}
			}

			#catch{
				position: relative;
				height: 100%;

				h1{
					@media #{$mq_s-m-l_s}{
						margin-bottom: -4px;
					}
					@media #{$mq_s-m-l_m}{
						margin-bottom: -6px;
					}

					@media #{$mq_sm-l_sm}{
						position: relative;
						padding: 0px 0px 0px 0px;
					}
					@media #{$mq_s-m-l_l}{
						position: absolute;
						top: 50%;
						padding: 100px 100px 100px 0px;
						transform: translateY(-50%);
					}
				}
			}

			.section{
				margin-top: 91px;
				opacity: 0;
				transform: translateY(30px);
				transition: opacity 1s ease-out, transform 1s ease-out;

				&.inview{
					opacity: 1;
					transform: translateY(0px);
				}

				&.contact{
					h2{
						font-size: 14px;
					}
				}

				h2{
					padding-bottom: 21px;
				}

				.body{
					@media #{$mq_sm-l_sm}{
						padding-right: 0px;
					}
					@media #{$mq_s-m-l_l}{
						padding-right: 100px;
					}
					font-size: 14px;
					line-height: 21px;

					dl{
						dt{
							width: 130px;
							letter-spacing: 0em;
							padding-bottom: 9px;
							opacity: .5;
							float: left;
						}

						dd{
							margin-left: 130px;
							padding-bottom: 9px;
						}
					}

					.more{
						margin-top: 10px;
					}
				}

				&.key_points{
					.body{
						dl{
							margin-top: 19px;

							dt{
								width: 15px;
							}
							dd{
								margin-left: 15px;
							}
						}

						.link{
							margin-top: 10px;
						}
					}
				}

				&.information{
					.body{
						dl{
							dt{
								&.hightlight{
									color: $hiperdyne_yellow;
									opacity: 1;
								}
							}

							dd{
								&.hightlight{
									color: $hiperdyne_yellow;

									a{
										color: $hiperdyne_yellow;

										&.hover{
											color: $hiperdyne_black;
											background-color: $hiperdyne_yellow;
										}
									}
								}
							}
						}
					}
				}

				&.recruit{
					.body{
						.link{
							margin-top: 19px;
						}
					}
				}
			}
		}
	}

	#footer{
		@media #{$mq_sm-l_sm}{
			position: relative;
			margin: 0px 20px 10px 20px;
		}
		@media #{$mq_sm-l_l}{
			position: fixed;
			bottom: 0px;
			left: 50%;
		}

		.copyright{
			font-size: 10px;
		}
	}
}

#webfont_loading_checker{
	position: absolute;
	top: 0px;
	left: -100px;
	font-family: $font_load_checker;
	font-size: 24px;
	letter-spacing: 0;
}