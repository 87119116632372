// COLOR

$hiperdyne_white: #fff;
$hiperdyne_black: #000;
$hiperdyne_yellow: #cdc000;



// FONTS

$font_gotham: 'Gotham A', 'Gotham B', _YuGothic, 'Yu Gothic', YuGothic, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Meiryo, sans-serif;
$font_weight_book: 400;
$font_weight_bold: 700;
$font_load_checker: 'Gotham A', AdobeBlank;


// MEDIA QUERY : S - M - L

$mq_s-m-l_s: 'all and (max-width:375px)';
$mq_s-m-l_m: 'all and (min-width:376px) and (max-width:768px)';
$mq_s-m-l_l: 'all and (min-width:769px)';

// MEDIA QUERY : SM - L

$mq_sm-l_sm: 'all and (max-width:768px)';
$mq_sm-l_l: 'all and (min-width:769px)';

// MEDIA QUERY : S - ML

$mq_s-ml_s: 'all and (max-width:375px)';
$mq_s-ml_ml: 'all and (min-width:376px)';



// Z-INDEX

$z-index_language: 100;