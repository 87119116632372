@font-face{
	font-family: '_YuGothic';
	src: local('Yu Gothic');
	font-weight: 300;
}

@font-face{
	font-family: '_YuGothic';
	src: local('Yu Gothic');
	font-weight: 500;
}

@font-face{
	font-family: '_YuGothic';
	src: local('Yu Gothic');
	font-weight: 700;
}

@font-face{
	font-family: '_YuGothic';
	src: local('Yu Gothic');
	font-weight: bold;
}